<template>
  <div class="rows books">
    <div class="card mt-2 pt-2">
      <h2 class="title h2 text-center"> Detail Revenue </h2>
      <div class="card-body">
        <div class="col-md-12">
          <b-table
            :items="items"
            :fields="fields"
            hover
            responsive
            :busy="isLoadingDetail"

            >
            <template #cell(No)="row">
              <router-link :to="{ path: `/order/detail?ordernumber=${row.item.order_number}`}">
                {{ row.item.order_number }}
              </router-link>
            </template>
            <template #cell(offer_name)="row">
              <div style="width:auto;min-width: 10em;">
                {{  row.item.offer_name }}
              </div>
            </template>
            <template #cell(price)="row">
              {{ formatPrice(row.item.price,row.item.currency_code) }}
            </template>
            <template #cell(discount)="row">
              {{ formatPrice(row.item.discount,row.item.currency_code) }}
            </template>
            <template #cell(paymentgateway_cost)="row">
              {{ formatPrice(row.item.paymentgateway_cost,row.item.currency_code) }}
            </template>
            <template #cell(final_price)="row">
              {{ formatPrice(row.item.final_price,row.item.currency_code) }}
            </template>
            <template #cell(balance)="row">
              {{ formatPrice(row.item.balance,row.item.currency_code) }}
            </template>
            <template #cell(disbursement)="row">
              <b>
                {{ formatPrice(row.item.disbursement,"IDR") }}
              </b>
            </template>
            <template #cell(payment_date)="row">
              <div style="width:auto;min-width: 12em;">
                {{ new Date(row.item.payment_date) }}
              </div>
            </template>

            <template #cell(client_id)="row">
              <span v-if="row.item.client_id ==1">
                <i class="fa fa-apple" aria-hidden="true"> App </i> 
              </span>
              <span v-else-if="row.item.client_id ==2">
                <i class="fa fa-android" aria-hidden="true"> App </i> 
              </span>
              <span v-else-if="row.item.client_id ==67">
                Eperpus
              </span>
              <span v-else-if="row.item.client_id ==7">
                Web Ebooks
              </span>
              <span v-else-if="row.item.client_id ==86">
                Web Gramedia.com
              </span>
            </template>
            <template #cell(payment)="row">
              <gatewayview :gateway="row.item.paymentgateway_id" />
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions} from 'vuex'
import moment from "moment";
import gatewayview from '../../components/payments/gateway.vue';
  export default {
    name: 'DetailRevenue',
    components:{
      gatewayview
    },
    data () {
      return {
        Dates: {
          from: moment().format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        },
        range: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
        masks: {
          input: "YYYY-MM-DD",
        },
        currentPage: 1,
        perPage: 100,
        fields:[
          { key:'No', label: 'OrderNumber'},
          { key:'offer_name', label: 'Offer Name'},
          { key:'vendor_name', label: 'Publisher'},
          { key:'price', label: 'Price'},
          { key:'discount', label:'discount'},
          { key:'quantity', label: 'Quantity'},
          { key :'final_price', label: 'Final Price'},
          { key :'paymentgateway_cost', label:'Payment Cost'},
          { key:'balance', label: 'Balance'},
          { key:'disbursement', label: 'Amount' },
          { key:'discount_name', label: 'Discount Name'},
          { key:'payment_date', label: 'Payment Date'},
          { key:'client_id', label: 'Client'},
          { key:'Payment', label:'Payment'},
          { key :'description', label: 'Description'},
          { key:'revenue_name',label:'Revenue Formula'}
        ],
      }
    },
    computed: {
      ...mapState({
        items: (state) => state.subledger.detailRevenue,
        isLoadingDetail: (state) => state.subledger.isLoadingDetailRevenue,
      })
    },
    watch: {
      currentPage: function() {
        this.actionSearch()
      }
    },
    mounted() {
      this.actionSearch()
    },
    methods: {
      //
      ...mapActions('subledger',['fetchByTransactionNonPremium']),
      actionSearch(){
        let trans_id = this.$route.params.trans_id
        let payload = {
          trans_id: trans_id,
        };
        
        this.fetchByTransactionNonPremium(payload)
      },
      formatPrice(value, currency) {
        if (value) {
          let formatter = null;
          if (currency == "USD") {
            formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            });
          } else {
            formatter = new Intl.NumberFormat("en-ID", {
              style: "currency",
              currency: currency,
            });
          }

          return formatter.format(value);
        }

        return "0";
      },
    }
  }
</script>
<style scoped>
.books {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>