var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rows books"
  }, [_c('div', {
    staticClass: "card mt-2 pt-2"
  }, [_c('h2', {
    staticClass: "title h2 text-center"
  }, [_vm._v(" Detail Revenue ")]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('b-table', {
    attrs: {
      "items": _vm.items,
      "fields": _vm.fields,
      "hover": "",
      "responsive": "",
      "busy": _vm.isLoadingDetail
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: ("/order/detail?ordernumber=" + (row.item.order_number))
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.order_number) + " ")])];
      }
    }, {
      key: "cell(offer_name)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "auto",
            "min-width": "10em"
          }
        }, [_vm._v(" " + _vm._s(row.item.offer_name) + " ")])];
      }
    }, {
      key: "cell(price)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.price, row.item.currency_code)) + " ")];
      }
    }, {
      key: "cell(discount)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.discount, row.item.currency_code)) + " ")];
      }
    }, {
      key: "cell(paymentgateway_cost)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.paymentgateway_cost, row.item.currency_code)) + " ")];
      }
    }, {
      key: "cell(final_price)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.final_price, row.item.currency_code)) + " ")];
      }
    }, {
      key: "cell(balance)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.balance, row.item.currency_code)) + " ")];
      }
    }, {
      key: "cell(disbursement)",
      fn: function (row) {
        return [_c('b', [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.disbursement, "IDR")) + " ")])];
      }
    }, {
      key: "cell(payment_date)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "auto",
            "min-width": "12em"
          }
        }, [_vm._v(" " + _vm._s(new Date(row.item.payment_date)) + " ")])];
      }
    }, {
      key: "cell(client_id)",
      fn: function (row) {
        return [row.item.client_id == 1 ? _c('span', [_c('i', {
          staticClass: "fa fa-apple",
          attrs: {
            "aria-hidden": "true"
          }
        }, [_vm._v(" App ")])]) : row.item.client_id == 2 ? _c('span', [_c('i', {
          staticClass: "fa fa-android",
          attrs: {
            "aria-hidden": "true"
          }
        }, [_vm._v(" App ")])]) : row.item.client_id == 67 ? _c('span', [_vm._v(" Eperpus ")]) : row.item.client_id == 7 ? _c('span', [_vm._v(" Web Ebooks ")]) : row.item.client_id == 86 ? _c('span', [_vm._v(" Web Gramedia.com ")]) : _vm._e()];
      }
    }, {
      key: "cell(payment)",
      fn: function (row) {
        return [_c('gatewayview', {
          attrs: {
            "gateway": row.item.paymentgateway_id
          }
        })];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }